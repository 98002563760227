import React from "react";
import { FaDev, FaGithub, FaLinkedin } from "react-icons/fa";
import InterestingThings from "./InterestingThings";
const About = () => {
  return (
    <div id="aboutMe">
      <h2>About Me</h2>
      <div className="aboutRow">
        <div className="aboutMeImage">
          <img src="../../img/Josie_Daw.jpg" alt="Josie Daw" />
        </div>
        <div className="aboutMeText">
          <p>
          I'm a British developer actively contributing to the growth of South Korea’s tech scene by leveraging technologies like NextJS, PHP, and React Native to build impactful projects. My academic background in History adds a unique perspective to problem-solving and project execution.
          </p>
          <p>
            I organize major tech events like the annual <a
              target="__blank"
              rel="noreferrer"
              href="https://www.seoultechimpact.com"
            >
              Seoul Tech Impact
            </a> hackathon and lead initiatives like <a
              target="__blank"
              rel="noreferrer"
              href="https://constantcoding.com"
            >
              Constant Coding
            </a> to support diverse talent entering tech.
            My experience spans coding, mentoring, and managing projects that drive impact, and I’m always open to new challenges and collaborations.
          </p>
          <div className="centerRow appIconRow">
            <a
              href="https://www.linkedin.com/in/josiedaw/"
              className="iconButton"
              target="__blank"
              rel="noreferrer"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://github.com/JosDaw"
              target="__blank"
              rel="noreferrer"
              className="iconButton"
            >
              <FaGithub />
            </a>
            <a
              href="https://dev.to/josie"
              target="__blank"
              rel="noreferrer"
              className="iconButton"
            >
              <FaDev />
            </a>
          </div>
          <InterestingThings />
        </div>
      </div>
    </div>
  );
};

export default About;

import React from "react";
import LazyLoad from "react-lazyload";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import projects from "../assets/projects.json";
import Project from "./Project";

const Portfolio = () => {
  const allProjects = projects;

  return (
    <div id="portfolio">
      <h2>Portfolio</h2>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry>
          {allProjects.map((project, index) => {
            return (
              <LazyLoad height={200} throttle={200} key={index}>
                <Project
                  title={project.title}
                  subtitle={project.subtitle}
                  techStack={project.techStack}
                  images={project.images}
                  content={project.content}
                  liveSite={project.liveSite}
                  github={project.github}
                  role={project.role}
                  appLinks={project.appLinks}
                  prizes={project.prizes}
                  isProjectOnly={project.isProjectOnly}
                />
              </LazyLoad>
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default Portfolio;
